import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ss-card ss-card-space" }
const _hoisted_2 = { class: "ant-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_apresentar_miniatura_campo_personalizado = _resolveComponent("apresentar-miniatura-campo-personalizado")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      keyboard: false,
      visible: _ctx.modalBase.computedVisivel,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
      style: {"width":"727px"},
      footer: null
    }, {
      title: _withCtx(() => [
        _createTextVNode("Seleção de Campo Personalizado")
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "vertical" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.campos, (campo, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_apresentar_miniatura_campo_personalizado, {
                      campo: _ctx.props.campos[index],
                      "onUpdate:campo": ($event: any) => ((_ctx.props.campos[index]) = $event),
                      mostrarExcluir: false,
                      onClickCampo: _ctx.confirmar
                    }, null, 8, ["campo", "onUpdate:campo", "onClickCampo"])
                  ]))
                }), 128)),
                _createElementVNode("div", null, [
                  _createVNode(_component_a_form_item, { label: " " }, {
                    default: _withCtx(() => [
                      _createElementVNode("button", {
                        type: "button",
                        class: "ant-btn ant-btn-block",
                        style: {"min-height":"45px","width":"100px","margin-left":"5px","margin-right":"5px"},
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.criarCampo && _ctx.criarCampo(...args)))
                      }, [
                        _createVNode(_component_icone, {
                          style: {"font-size":"20px"},
                          nome: "novo"
                        })
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}