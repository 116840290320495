
import {
  computed, defineComponent, reactive,
} from 'vue';
import draggable from 'vuedraggable';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  components: {
    draggable,
    Icone,
  },
  props: {
    visivel: {
      type: Boolean,
    },
    colunas: {
      type: Array as () => IColumn[],
      required: true,
    },
  },
  setup(props, { emit }) {
    const tela = reactive({
      computedVisivel: computed({
        get: () => props.visivel,
        set: (value: boolean) => {
          emit('update:visivel', value);
        },
      }),
      computedColunas: computed({
        get: () => props.colunas,
        set: (value: IColumn[]) => {
          emit('update:colunas', value);
        },
      }),
    });

    return {
      tela,
    };
  },
});
