
import { defineComponent, reactive } from 'vue';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'NovaLinhaModal',
  props: {
    visivel: {
      type: Boolean,
    },
  },
  components: {
    Icone,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      qtdColunas: 1,
    });

    function confirmar() {
      modalBase.computedVisivel = false;
      emit('confirmacao', state.qtdColunas);
    }

    return {
      props,
      state,
      modalBase,
      confirmar,
    };
  },
});
