
import {
  defineComponent, reactive, computed, onMounted,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarTipoElemento from '@/components/MeuSistema/PersonalizacoesTelas/SelecionarTipoElemento.vue';
import { IGrupoCamposPersonalizado } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import ServicoPersonalizacaoTelas from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'ApresentarGrupoCampos',
  props: {
    grupo: {
      type: Object as () => IGrupoCamposPersonalizado,
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarTipoElemento,
  },
  emits: ['update:grupo', 'editarGrupo', 'removerGrupo'],
  setup(props, { emit }) {
    const servicoPersonalizacaoTelas = new ServicoPersonalizacaoTelas();

    const state = reactive({
      carregando: false,
    });

    const computedGrupo = computed({
      get: () => props.grupo,
      set: (grupo: IGrupoCamposPersonalizado) => {
        emit('update:grupo', grupo);
      },
    });

    onMounted(async () => {
      state.carregando = true;
      if (props.grupo.codigo > 0) {
        computedGrupo.value.linhas = await servicoPersonalizacaoTelas.obterLinhasCampos(props.grupo.codigo, storeSistema.getters.codigoEmpresaOperacao());
        computedGrupo.value.campos = await servicoPersonalizacaoTelas.obterCamposPersonalizados(props.grupo.codigo, storeSistema.getters.codigoEmpresaOperacao());
      }
      state.carregando = false;
    });

    function editarGrupo() {
      emit('editarGrupo');
    }

    function removerGrupo() {
      emit('removerGrupo');
    }

    return {
      props,
      state,
      computedGrupo,
      editarGrupo,
      removerGrupo,
    };
  },
});
