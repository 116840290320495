
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IEmpresaCadastroCompartilhado } from '@/models/Entidades/MeuSistema/Empresas/IEmpresaCadastroCompartilhado';

export default defineComponent({
  name: 'SelecionarEmpresaCadastroCompartilhado',
  props: {
    identificadorRecurso: {
      type: String,
      default: '',
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigosSelecionados', 'change'],
  setup(props, { emit }) {
    const state = reactive({
      listaEmpresas: [] as IOption[],
      empresasCompartilhandoCadastro: [] as IEmpresaCadastroCompartilhado[],
    });
    state.listaEmpresas = [];
    const empresasUsuario = storeSistema.getters.dadosEmpresasAutorizadasUsuario();
    state.listaEmpresas = empresasUsuario.map((empresa) => ({
      label: UtilitarioGeral.montaExibicaoEmpresa(empresa.codigoExibicao, empresa.nomeExibicao),
      value: empresa.codigoEmpresa,
    }));
    (async () => {
      if (storeSistema.getters.multiEmpresas()) {
        const servicoSistema = new ServicoSistema();
        state.empresasCompartilhandoCadastro = await servicoSistema.obterEmpresasComCadastrosCompartilhados(props.identificadorRecurso);
        if (UtilitarioGeral.validaLista(state.empresasCompartilhandoCadastro)) {
          state.empresasCompartilhandoCadastro.forEach((dadosEmpresa) => {
            const empresaAdicionada = state.listaEmpresas.find((c) => c.value === dadosEmpresa.codigoEmpresa);
            if (empresaAdicionada === undefined) {
              const dadosExibicao = storeSistema.state.empresasDisponiveis.find((c) => c.codigoEmpresa === dadosEmpresa.codigo);
              if (dadosExibicao !== undefined) {
                const opcaoEmpresa:IOption = {} as IOption;
                opcaoEmpresa.value = dadosExibicao.codigoEmpresa;
                opcaoEmpresa.label = UtilitarioGeral.montaExibicaoEmpresa(dadosExibicao.codigoExibicao, dadosExibicao.nomeExibicao);
                state.listaEmpresas.push(opcaoEmpresa);
              }
            }
          });
        }
      }
    })();

    function preencheEmpresasCompartilhandoCadastro(codigoEmpresa:number):number[] {
      const codigoEmpresas:number[] = [];
      const empresaSelecionada = state.empresasCompartilhandoCadastro.find((c) => c.codigoEmpresa === codigoEmpresa);
      if (empresaSelecionada !== undefined) {
        if (empresaSelecionada.compartilhar) {
          state.empresasCompartilhandoCadastro.forEach((dadosEmpresa) => {
            if (dadosEmpresa.compartilhar) {
              codigoEmpresas.push(dadosEmpresa.codigoEmpresa);
            }
          });
        } else {
          codigoEmpresas.push(codigoEmpresa);
        }
        emit('update:codigosSelecionados', codigoEmpresas);
      }
      return codigoEmpresas;
    }

    function obtemEmpresas():number[] {
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obtemEmpresas(),
      set: (valor: number[]) => {
        if (storeSistema.getters.multiEmpresas()) {
          const ultimoCodigo = valor[valor.length - 1];
          preencheEmpresasCompartilhandoCadastro(ultimoCodigo);
        } else {
          emit('update:codigosSelecionados', valor);
        }
      },
    });

    function exibirEmpresa():boolean {
      if (state.listaEmpresas.length > 1) {
        return true;
      }

      return false;
    }

    function change() {
      emit('change', props.codigosSelecionados);
    }

    return {
      props,
      state,
      computedCodigosSelecionados,
      exibirEmpresa,
      change,
    };
  },
});
