
import {
  defineComponent, reactive, watch, computed,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import {
  ICampoPersonalizado, IColunaCamposPersonalizados, IGrupoCamposPersonalizado, IItemCampoPersonalizado, ILinhaCamposPersonalizados,
} from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import ApresentarLinhaColunas from '@/components/MeuSistema/PersonalizacoesTelas/ApresentarLinhaColunas.vue';
import ApresentarMiniaturaCampoPersonalizado from '@/components/MeuSistema/PersonalizacoesTelas/ApresentarMiniaturaCampoPersonalizado.vue';
import NovaLinhaModal from '@/views/MeuSistema/PersonalizacoesTelas/NovaLinhaModal.vue';
import CampoPersonalizadoModal from '@/views/MeuSistema/PersonalizacoesTelas/CampoPersonalizadoModal.vue';
import SelecaoCampoPersonalizadoModal from '@/views/MeuSistema/PersonalizacoesTelas/SelecaoCampoPersonalizadoModal.vue';
import { ETipoCampoPersonalizado } from '@/models/Enumeradores/MeuSistema/PersonalizacoesTelas/ETipoCampoPersonalizado';

export default defineComponent({
  name: 'GrupoCamposModal',
  props: {
    visivel: {
      type: Boolean,
    },
    grupo: {
      type: Object as () => IGrupoCamposPersonalizado,
      required: true,
    },
  },
  emits: ['update:visivel', 'update:grupo'],
  components: {
    RequisicaoModal,
    Icone,
    Card,
    NovaLinhaModal,
    CampoPersonalizadoModal,
    ApresentarLinhaColunas,
    ApresentarMiniaturaCampoPersonalizado,
    SelecaoCampoPersonalizadoModal,
    MensagemSemDados,
  },
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);

    const state = reactive({
      linhas: [] as ILinhaCamposPersonalizados[],
      campos: [] as ICampoPersonalizado[],
      camposOrfaos: [] as ICampoPersonalizado[],
      colunaSelecionada: {} as IColunaCamposPersonalizados,
      campoSelecionado: {} as ICampoPersonalizado,
      exibirModalLinha: false,
      exibirModalCampo: false,
      exibirModalSelecaoCampo: false,
    });

    const computedGrupo = computed({
      get: () => props.grupo,
      set: (grupo: IGrupoCamposPersonalizado) => {
        emit('update:grupo', grupo);
      },
    });

    function preparaObjetoInicial() {
      state.linhas = props.grupo.linhas;
      state.campos = props.grupo.campos;
    }

    function adicionarLinha() {
      state.exibirModalLinha = true;
    }

    function criarLinha(qtdColunas: number) {
      const linha:ILinhaCamposPersonalizados = {} as ILinhaCamposPersonalizados;
      let contador = 0;
      linha.codigo = 0;
      linha.codigoGrupoCamposPersonalizados = props.grupo.codigo;
      linha.qtdColunas = qtdColunas;
      linha.ordem = state.linhas.length + 1;
      linha.colunas = [];

      for (contador; contador < linha.qtdColunas; contador += 1) {
        const coluna:IColunaCamposPersonalizados = {} as IColunaCamposPersonalizados;
        coluna.ordem = linha.colunas.length + 1;
        linha.colunas.push(coluna);
      }
      state.linhas.push(linha);
    }

    function obterColuna(campo: ICampoPersonalizado) {
      for (let contLinha = 0; contLinha < state.linhas.length; contLinha += 1) {
        for (let contColuna = 0; contColuna < state.linhas[contLinha].colunas.length; contColuna += 1) {
          if (state.linhas[contLinha].colunas[contColuna].campoPersonalizado !== undefined) {
            if (state.linhas[contLinha].colunas[contColuna].campoPersonalizado?.identificador === campo.identificador) {
              return state.linhas[contLinha].colunas[contColuna];
            }
          }
        }
      }
      return undefined;
    }

    function contarOrfaos() {
      let contadorOrfaos = 0;
      for (let contador = 0; contador < state.campos.length; contador += 1) {
        if (obterColuna(state.campos[contador]) === undefined) {
          contadorOrfaos += 1;
        }
      }
      return contadorOrfaos;
    }

    function preencherOrfaos() {
      state.camposOrfaos = [] as ICampoPersonalizado[];
      for (let contador = 0; contador < state.campos.length; contador += 1) {
        if (obterColuna(state.campos[contador]) === undefined) {
          state.camposOrfaos.push(state.campos[contador]);
        }
      }
    }

    function selecionarCampo(coluna: IColunaCamposPersonalizados) {
      preencherOrfaos();
      state.colunaSelecionada = coluna;
      state.exibirModalSelecaoCampo = true;
    }

    function definirCampoSelecionado(campo: ICampoPersonalizado) {
      state.colunaSelecionada.campoPersonalizado = campo;
      state.colunaSelecionada.codigoCampoPersonalizado = campo.codigo;
    }

    function criarCampo() {
      state.campoSelecionado = {} as ICampoPersonalizado;
      state.campoSelecionado.codigo = 0;
      state.campoSelecionado.tipoCampo = ETipoCampoPersonalizado.Texto;
      state.campoSelecionado.itens = [] as IItemCampoPersonalizado[];
      state.exibirModalCampo = true;
    }

    function editarCampo(campo: ICampoPersonalizado, coluna?: IColunaCamposPersonalizados) {
      state.campoSelecionado = campo;
      if (coluna !== undefined) {
        state.colunaSelecionada = coluna;
      } else {
        state.colunaSelecionada = {} as IColunaCamposPersonalizados;
      }
      state.exibirModalCampo = true;
    }

    function validarUtilizacaoIdentificadorCampo(codigo: number, identificador: string) {
      const indice = state.campos.findIndex((c) => c.identificador === identificador && c.codigo !== codigo);
      return indice >= 0;
    }

    function atualizarCampo() {
      if (validarUtilizacaoIdentificadorCampo(state.campoSelecionado.codigo, state.campoSelecionado.identificador)) {
        apresentarMensagemAlerta(`Identificador ${state.campoSelecionado.identificador} já foi utilizado.`);
        return;
      }
      state.colunaSelecionada.campoPersonalizado = state.campoSelecionado;
      state.colunaSelecionada.codigoCampoPersonalizado = state.campoSelecionado.codigo;
      if (state.campoSelecionado.codigo === 0) {
        state.campos.push(state.campoSelecionado);
      }
      const indiceCampo = state.campos.findIndex((c) => c.identificador === state.campoSelecionado.identificador);
      state.campos[indiceCampo] = state.campoSelecionado;
    }

    function removerLinha(indiceLinha: number) {
      state.linhas.splice(indiceLinha, 1);
    }

    function removerCampo(campo: ICampoPersonalizado, coluna?: IColunaCamposPersonalizados) {
      if (coluna !== undefined) {
        state.colunaSelecionada = coluna;
        state.colunaSelecionada.codigoCampoPersonalizado = 0;
        delete state.colunaSelecionada.campoPersonalizado;
      } else {
        Modal.confirm({
          title: 'Deseja o campo:',
          content: `${campo.nome} dos campos do grupo?`,
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: null,
          onOk: () => {
            const indiceCampo = state.campos.findIndex((c) => c.identificador === campo.identificador);
            state.campos.splice(indiceCampo, 1);
          },
        });
      }
    }

    function confirmar() {
      computedGrupo.value.linhas = state.linhas;
      computedGrupo.value.campos = state.campos;
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      preparaObjetoInicial();
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      apresentarRetornoRequisicao,
      preparaObjetoInicial,
      computedGrupo,
      adicionarLinha,
      criarLinha,
      obterColuna,
      definirCampoSelecionado,
      criarCampo,
      editarCampo,
      atualizarCampo,
      selecionarCampo,
      confirmar,
      contarOrfaos,
      removerCampo,
      removerLinha,
      preencherOrfaos,
    };
  },
});
