import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    class: "ss-tamanho-completo",
    value: _ctx.computedTipoElementoSelecionado,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedTipoElementoSelecionado) = $event)),
    onChange: _ctx.change,
    placeholder: _ctx.props.placeholder,
    disabled: _ctx.props.disabled,
    defaultValue: 1
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_select_option, { value: 1 }, {
        default: _withCtx(() => [
          _createTextVNode("Aba")
        ]),
        _: 1
      }),
      _createVNode(_component_a_select_option, { value: 2 }, {
        default: _withCtx(() => [
          _createTextVNode("Painel")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "onChange", "placeholder", "disabled"]))
}