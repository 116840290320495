
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SelecionarTipoElemento',
  props: {
    tipoElemento: {
      type: Number,
      default: 1,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:tipoElemento', 'change'],
  setup(props, { emit }) {
    const computedTipoElementoSelecionado = computed({
      get: () => props.tipoElemento,
      set: (tipoElemento: number) => {
        emit('update:tipoElemento', tipoElemento);
      },
    });

    function change() {
      emit('change', props.tipoElemento);
    }

    return {
      props,
      change,
      computedTipoElementoSelecionado,
    };
  },
});
