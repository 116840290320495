import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bece172"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24 ss-display-flex" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apresentar_miniatura_campo_personalizado = _resolveComponent("apresentar-miniatura-campo-personalizado")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createBlock(_component_a_skeleton, {
    active: "",
    loading: _ctx.state.carregando,
    paragraph: { rows: 0 }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.linha.colunas, (coluna, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "ss-div-colunas"
            }, [
              (coluna.campoPersonalizado !== undefined)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_apresentar_miniatura_campo_personalizado, {
                      campo: coluna.campoPersonalizado,
                      "onUpdate:campo": ($event: any) => ((coluna.campoPersonalizado) = $event),
                      onClickCampo: ($event: any) => (_ctx.editarCampo(index, coluna.campoPersonalizado)),
                      onRemoverCampo: ($event: any) => (_ctx.removerCampo(_ctx.props.linha.colunas[index]))
                    }, null, 8, ["campo", "onUpdate:campo", "onClickCampo", "onRemoverCampo"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_a_form_item, {
                      class: "ss-margin-campos",
                      label: " "
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("button", {
                          type: "button",
                          class: "ss-botao-novo ant-btn",
                          onClick: ($event: any) => (_ctx.selecionarCampo(index))
                        }, [
                          _createVNode(_component_icone, {
                            class: "ss-icone-novo",
                            nome: "novo"
                          })
                        ], 8, _hoisted_5)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}