
import { defineComponent, reactive } from 'vue';
import Icone from '@/core/components/Icone.vue';
import ApresentarMiniaturaCampoPersonalizado from '@/components/MeuSistema/PersonalizacoesTelas/ApresentarMiniaturaCampoPersonalizado.vue';
import { useModalBase } from '@/core/composables/ModalBase';
import { ICampoPersonalizado } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';

export default defineComponent({
  name: 'SelecaoCampoPersonalizadoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    campos: {
      type: Object as () => ICampoPersonalizado[],
      required: true,
    },
  },
  components: {
    Icone,
    ApresentarMiniaturaCampoPersonalizado,
  },
  emits: ['criarCampo', 'update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({});

    function criarCampo() {
      modalBase.computedVisivel = false;
      emit('criarCampo');
    }

    function confirmar(campo: ICampoPersonalizado) {
      modalBase.computedVisivel = false;
      emit('confirmacao', campo);
    }

    return {
      props,
      state,
      modalBase,
      criarCampo,
      confirmar,
    };
  },
});
