
import {
  computed, defineComponent, onMounted, reactive,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { ETipoCampoPersonalizado } from '@/models/Enumeradores/MeuSistema/PersonalizacoesTelas/ETipoCampoPersonalizado';
import { ICampoPersonalizado } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import ServicoPersonalizacaoTela from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'ApresentarMiniaturaCampoPersonalizado',
  props: {
    campo: {
      type: Object as () => ICampoPersonalizado,
      required: true,
    },
    mostrarExcluir: {
      type: Boolean,
      default: true,
    },
    carregarItens: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:campo', 'clickCampo', 'removerCampo'],
  components: {
    Icone,
  },
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const servicoPersonalizacaoTelas = new ServicoPersonalizacaoTela();

    const state = reactive({
      carregando: false,
    });

    const computedCampo = computed({
      get: () => props.campo,
      set: (campo: ICampoPersonalizado) => {
        emit('update:campo', campo);
      },
    });

    onMounted(async () => {
      state.carregando = true;
      if (props.campo.codigo > 0 && (props.campo.tipoCampo === ETipoCampoPersonalizado.ComboBox || props.campo.tipoCampo === ETipoCampoPersonalizado.ListaSelecao)) {
        computedCampo.value.itens = await servicoPersonalizacaoTelas.obterItensCampo(props.campo.codigo, storeSistema.getters.codigoEmpresaOperacao());
      }
      state.carregando = false;
    });

    function obterIcone() {
      switch (props.campo.tipoCampo) {
        case ETipoCampoPersonalizado.Texto:
          return 'texto';
        case ETipoCampoPersonalizado.AreaTexto:
          return 'area-texto';
        case ETipoCampoPersonalizado.Email:
          return 'arroba';
        case ETipoCampoPersonalizado.Senha:
          return 'senha';
        case ETipoCampoPersonalizado.Inteiro:
          return 'numero';
        case ETipoCampoPersonalizado.Fracionado:
          return 'dinheiro';
        case ETipoCampoPersonalizado.ComboBox:
          return 'combobox';
        case ETipoCampoPersonalizado.ListaSelecao:
          return 'checklist';
        case ETipoCampoPersonalizado.Data:
          return 'calendario';
        case ETipoCampoPersonalizado.Hora:
          return 'relogio';
        case ETipoCampoPersonalizado.Check:
          return 'check';
        case ETipoCampoPersonalizado.Switch:
          return 'switch';
        default:
          return 'texto';
      }
    }

    function clickCampo() {
      emit('clickCampo', props.campo);
    }

    function removerCampo() {
      emit('removerCampo', props.campo);
    }

    return {
      props,
      state,
      telaBase,
      obterIcone,
      clickCampo,
      removerCampo,
    };
  },
});
