
import { computed, defineComponent, reactive } from 'vue';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';

export default defineComponent({
  name: 'SelecionarRecurso',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    recursoSelecionado: {
      type: String,
      default: '',
    },
    recursosSelecionados: {
      type: Array as () => string[],
      default: () => [],
    },
    apenasAutorizadosUsuario: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:recursoSelecionado', 'update:recursosSelecionados', 'change'],
  setup(props, { emit }) {
    const servicoSistema = new ServicoSistema();

    const state = reactive({
      listaRecursos: [] as IOptionString[],
    });

    state.listaRecursos = [];

    (async () => {
      let recursos;
      if (props.apenasAutorizadosUsuario) {
        recursos = await servicoSistema.obterRecursos('', storeSistema.getters.empresasUsuarioAutenticado());
      } else {
        recursos = await servicoSistema.obterTodosRecursos();
      }
      state.listaRecursos = recursos.map((recurso) => ({
        label: recurso.descricao,
        value: recurso.identificador,
      }));
    })();

    const computedRecursoSelecionado = computed({
      get: () => props.recursoSelecionado,
      set: (val: string) => {
        emit('update:recursoSelecionado', val);
      },
    });

    const computedRecursosSelecionados = computed({
      get: () => props.recursosSelecionados,
      set: (val: string[]) => {
        emit('update:recursosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.recursosSelecionados : props.recursoSelecionado);
    }

    return {
      props,
      state,
      computedRecursoSelecionado,
      computedRecursosSelecionados,
      change,
    };
  },
});
