import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      class: "ss-tamanho-completo",
      value: _ctx.computedCodigosSelecionados,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigosSelecionados) = $event)),
      "show-search": "",
      mode: "multiple",
      onChange: _ctx.change,
      allowClear: true,
      placeholder: _ctx.props.placeholder,
      optionFilterProp: "label",
      options: _ctx.state.listaEmpresas,
      disabled: (_ctx.props.disabled)
    }, null, 8, ["value", "onChange", "placeholder", "options", "disabled"])
  ]))
}