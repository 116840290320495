import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5045912c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_2 = { class: "ss-margin-campos" }
const _hoisted_3 = { class: "ant-col ant-col-xs-7 ant-col-xl-7" }
const _hoisted_4 = { class: "ss-margin-campos" }
const _hoisted_5 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_6 = { class: "ss-margin-campos" }
const _hoisted_7 = { class: "ant-col ant-col-xs-1 ant-col-xl-1" }
const _hoisted_8 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_9 = { class: "ant-col ant-col-xs-2 ant-col-xl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_tipo_elemento = _resolveComponent("selecionar-tipo-elemento")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createBlock(_component_a_skeleton, {
    active: "",
    loading: _ctx.state.carregando,
    paragraph: { rows: 0 }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            maxlength: "100",
            class: "ant-input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedGrupo.identificador) = $event))
          }, null, 512), [
            [_vModelText, _ctx.computedGrupo.identificador]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            maxlength: "100",
            class: "ant-input",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedGrupo.titulo) = $event))
          }, null, 512), [
            [_vModelText, _ctx.computedGrupo.titulo]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_selecionar_tipo_elemento, {
            tipoElemento: _ctx.computedGrupo.tipoElemento,
            "onUpdate:tipoElemento": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedGrupo.tipoElemento) = $event))
          }, null, 8, ["tipoElemento"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_a_checkbox, {
          checked: _ctx.computedGrupo.ativo,
          "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedGrupo.ativo) = $event)),
          class: "ss-checkbox-grande ss-margin-campos"
        }, null, 8, ["checked"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          type: "button",
          class: "ss-botao-conteudo ant-btn ant-btn-primary",
          title: "Conteudo",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editarGrupo()))
        }, "Conteúdo")
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          type: "button",
          class: "ss-botao-remover ss-margin-campos ant-btn ant-btn-primary",
          title: "Remover",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removerGrupo()))
        }, [
          _createVNode(_component_icone, {
            class: "ss-icone-remover",
            nome: "excluir"
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}