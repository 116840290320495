import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    value: _ctx.computedValor,
    onChange: _ctx.defineValorSelecionado,
    class: "ss-tamanho-completo"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_select_option, { value: true }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.props.descricaoTrue), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_select_option, { value: false }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.props.descricaoFalse), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "onChange"]))
}