import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed1e9ca2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ss-botao-campo ant-btn ant-btn-block" }
const _hoisted_3 = { class: "ant-row" }
const _hoisted_4 = { class: "ant-col-xl-22 ant-col-xs-22" }
const _hoisted_5 = {
  key: 0,
  class: "ant-col-xl-2 ant-col-xs-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createBlock(_component_a_skeleton, {
    key: _ctx.props.campo.codigo,
    active: "",
    loading: _ctx.state.carregando,
    paragraph: { rows: 0 }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        class: "ss-margin-campos",
        label: _ctx.props.campo.nome
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "ss-botao-interno ant-btn ant-btn-ghost",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickCampo && _ctx.clickCampo(...args)))
                  }, [
                    _createVNode(_component_icone, {
                      class: "ss-icone-miniatura",
                      nome: _ctx.obterIcone()
                    }, null, 8, ["nome"])
                  ])
                ]),
                (_ctx.props.mostrarExcluir)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "ss-botao-remover ant-btn ant-btn-ghost",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removerCampo && _ctx.removerCampo(...args)))
                      }, [
                        _createVNode(_component_icone, {
                          class: "ss-icone-remover",
                          nome: "excluir"
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }, 8, ["loading"]))
}