
import {
  defineComponent, reactive, ref, watch, computed,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarHora from '@/core/components/Tela/SelecionarHora.vue';
import SelecionarValorCampoPersonalizado from '@/core/components/Tela/SelecionarValorCampoPersonalizado.vue';
import SelecionarBoolean from '@/core/components/Tela/SelecionarBoolean.vue';
import { ICampoPersonalizado, IItemCampoPersonalizado } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import { ETipoCampoPersonalizado } from '@/models/Enumeradores/MeuSistema/PersonalizacoesTelas/ETipoCampoPersonalizado';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import ServicoPersonalizacaoTela from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'CampoPersonalizadoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    campo: {
      type: Object as () => ICampoPersonalizado,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarSimNao,
    CampoNumerico,
    MensagemSemDados,
    SelecionarValorCampoPersonalizado,
    SelecionarHora,
    SelecionarData,
    SelecionarBoolean,
  },
  emits: ['update:visivel', 'update:campo', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const campoNovoItem = ref<HTMLElement | null>(null);
    const servicoPersonalizacaoTela = new ServicoPersonalizacaoTela();

    const state = reactive({
      campo: {} as ICampoPersonalizado,
      novoItem: {} as IItemCampoPersonalizado,
      valorPadraoNumerico: 0,
      valorPadraoBoolean: false,
    });

    const computedCampo = computed({
      get: () => props.campo,
      set: (campo: ICampoPersonalizado) => {
        emit('update:campo', campo);
      },
    });

    function iniciarTela() {
      state.campo = {} as ICampoPersonalizado;
      state.campo.itens = [] as IItemCampoPersonalizado[];
      state.novoItem = {} as IItemCampoPersonalizado;
      state.valorPadraoBoolean = false;
      state.valorPadraoNumerico = 0;
      if (props.campo !== undefined) {
        state.campo = UtilitarioGeral.instanciaObjetoLocal(props.campo);
      }
    }

    function obterClasseBotao(tipo: ETipoCampoPersonalizado) {
      const classeCampo = 'ss-botao-tipo-campo ';
      if (tipo === state.campo.tipoCampo) {
        return `${classeCampo} ant-btn ant-btn-primary`;
      }
      if (state.campo.codigo > 0) {
        return `${classeCampo} ant-btn-block`;
      }
      return `${classeCampo} ant-btn ant-btn-block`;
    }

    function obterTamanhoMaximo() {
      if (state.campo.tamanhoMaximo === 0) {
        return 250;
      }

      return state.campo.tamanhoMaximo;
    }

    function selecionarTipo(tipo: ETipoCampoPersonalizado) {
      if (state.campo.codigo === 0) {
        state.campo.tipoCampo = tipo;
      }
    }

    function validarTipoTexto() {
      if (state.campo.tipoCampo === ETipoCampoPersonalizado.Texto || state.campo.tipoCampo === ETipoCampoPersonalizado.AreaTexto || state.campo.tipoCampo === ETipoCampoPersonalizado.Email) {
        return true;
      }
      return false;
    }

    function validarTipoNumerico() {
      if (state.campo.tipoCampo === ETipoCampoPersonalizado.Inteiro || state.campo.tipoCampo === ETipoCampoPersonalizado.Fracionado) {
        return true;
      }
      return false;
    }

    function validarTipoMultivalorado() {
      if (state.campo.tipoCampo === ETipoCampoPersonalizado.ComboBox || state.campo.tipoCampo === ETipoCampoPersonalizado.ListaSelecao) {
        return true;
      }
      return false;
    }

    function validarTipoBoolean() {
      if (state.campo.tipoCampo === ETipoCampoPersonalizado.Check || state.campo.tipoCampo === ETipoCampoPersonalizado.Switch) {
        return true;
      }
      return false;
    }

    function focoNovoItem() {
      if (campoNovoItem.value) {
        campoNovoItem.value.focus();
      }
    }

    function adicionarItem() {
      if (UtilitarioGeral.validaLista(state.campo.itens)) {
        const item = state.campo.itens.find((i) => i.valor === state.novoItem.valor || i.descricao === state.novoItem.descricao);
        if (UtilitarioGeral.valorValido(item)) {
          apresentarMensagemAlerta('Já foi adicionado um item com esse valor ou essa descrição.');
          focoNovoItem();
          return;
        }
      }

      if (state.novoItem.valor === undefined || state.novoItem.valor === '') {
        apresentarMensagemAlerta('O valor do item deve ser preenchido.');
        return;
      }

      if (state.novoItem.descricao === undefined || state.novoItem.descricao === '') {
        apresentarMensagemAlerta('A descrição do item deve ser preenchida.');
        return;
      }

      const item:IItemCampoPersonalizado = {} as IItemCampoPersonalizado;
      item.codigo = 0;
      item.codigoCampoPersonalizado = props.campo.codigo;
      item.valor = state.novoItem.valor;
      item.descricao = state.novoItem.descricao;

      state.campo.itens.push(item);
      state.novoItem = {} as IItemCampoPersonalizado;
      focoNovoItem();
    }

    function removerItem(index: number, descricao: string) {
      Modal.confirm({
        title: 'Deseja remover o item:',
        content: `${descricao} da lista de item?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { state.campo.itens.splice(index, 1); },
      });
    }

    function confirmar() {
      if (state.campo.identificador === undefined || state.campo.identificador === '') {
        apresentarMensagemAlerta('O identificador deve ser preenchido.');
        return;
      }

      if (state.campo.nome === undefined || state.campo.nome === '') {
        apresentarMensagemAlerta('O nome deve ser preenchido.');
        return;
      }

      if (validarTipoNumerico()) {
        state.campo.valorPadrao = String(state.valorPadraoNumerico);
      } else if (validarTipoBoolean()) {
        state.campo.valorPadrao = String(state.valorPadraoBoolean);
      }

      modalBase.computedVisivel = false;
      computedCampo.value = state.campo;
      emit('confirmacao');
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      iniciarTela();
      if (modalBase.computedVisivel) {
        if (props.campo !== undefined && props.campo.codigo > 0) {
          const itens = await servicoPersonalizacaoTela.obterItensCampo(props.campo.codigo, storeSistema.getters.codigoEmpresaOperacao());
          if (UtilitarioGeral.validaLista(itens)) {
            state.campo.itens = itens;
          }
          if (validarTipoNumerico()) {
            state.valorPadraoNumerico = Number(state.campo.valorPadrao);
          }
          if (validarTipoBoolean()) {
            state.valorPadraoBoolean = Boolean(state.campo.valorPadrao);
          } else {
            state.campo.valorPadrao = state.campo.valorPadrao.substring(0, obterTamanhoMaximo());
          }
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      ETipoCampoPersonalizado,
      computedCampo,
      iniciarTela,
      obterClasseBotao,
      obterTamanhoMaximo,
      selecionarTipo,
      validarTipoTexto,
      validarTipoNumerico,
      validarTipoMultivalorado,
      validarTipoBoolean,
      focoNovoItem,
      adicionarItem,
      removerItem,
      confirmar,
    };
  },
});
