
import {
  defineComponent, reactive, watch, ref, computed,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import {
  ICampoPersonalizado, IGrupoCamposPersonalizado, ILinhaCamposPersonalizados, IPersonalizacaoTela,
} from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarRecurso from '@/components/MeuSistema/SelecionarRecurso.vue';
import SelecionarTipoElemento from '@/components/MeuSistema/PersonalizacoesTelas/SelecionarTipoElemento.vue';
import ApresentarGrupoCampos from '@/components/MeuSistema/PersonalizacoesTelas/ApresentarGrupoCampos.vue';
import storeSistema from '@/store/storeSistema';
import ServicoPersonalizacaoTela from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import GrupoCamposModal from '@/views/MeuSistema/PersonalizacoesTelas/GrupoCamposModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'PersonalizacaoTelaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      default: {} as ITelaOperacao,
    },
    recurso: {
      type: String,
    },
  },
  components: {
    Icone,
    Card,
    MensagemSemDados,
    SelecionarEmpresa,
    SelecionarRecurso,
    SelecionarTipoElemento,
    ApresentarGrupoCampos,
    GrupoCamposModal,
    RequisicaoModal,
  },
  emits: ['update:visivel', 'update:operacao', 'update:grupos', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemErro } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const { apresentarMensagemAlerta } = useTelaBase();
    const servicoPersonalizacaoTela = new ServicoPersonalizacaoTela();
    const campoNovoGrupo = ref<HTMLElement | null>(null);
    telaBase.identificadorRecurso = 'PERSONALIZACOES_TELAS';
    telaBase.identificadorPermissao = 'AUT_PERSONALIZACOES_TELAS';

    const state = reactive({
      personalizacao: {} as IPersonalizacaoTela,
      novoGrupo: {} as IGrupoCamposPersonalizado,
      grupoEditado: {} as IGrupoCamposPersonalizado,
      montarModalGrupo: false,
      exibirModalGrupos: false,
      exibirBotaoExcluir: false,
      recursosDisponiveis: ['CADASTRO_PRODUTOS', 'CADASTRO_CLIENTES', 'CADASTRO_FORNECEDORES', 'CADASTRO_COLABORADORES', 'CADASTRO_TRANSPORTADORAS'],
    });

    const computedOperacao = computed({
      get: () => props.operacao,
      set: (operacao: ITelaOperacao) => {
        emit('update:operacao', operacao);
      },
    });

    const computedTela = computed({
      get: () => state.personalizacao.recurso,
      set: (tela: string) => {
        if (state.recursosDisponiveis.includes(tela)) {
          state.personalizacao.recurso = tela;
        } else {
          apresentarMensagemAlerta('Essa tela ainda não possui esse recurso,\n por favor entre em contato  com nosso suporte.');
          if (props.recurso !== undefined) {
            modalBase.computedVisivel = false;
          }
        }
      },
    });

    function preparaObjetoInicialGrupo() {
      state.novoGrupo = {} as IGrupoCamposPersonalizado;
      state.novoGrupo.tipoElemento = 1;
    }

    function preparaObjetoInicial() {
      state.personalizacao = {} as IPersonalizacaoTela;
      state.personalizacao.codigo = 0;
      state.personalizacao.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      state.personalizacao.gruposCamposPersonalizados = [] as IGrupoCamposPersonalizado[];
      if (props.recurso !== undefined && props.recurso !== '') {
        computedOperacao.value = {} as ITelaOperacao;
        computedOperacao.value.codigoRegistro = 0;
        computedOperacao.value.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
        state.personalizacao.recurso = props.recurso;
        state.personalizacao.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      }
      preparaObjetoInicialGrupo();
    }

    function focoNovoGrupo() {
      if (campoNovoGrupo.value) {
        campoNovoGrupo.value.focus();
      }
    }

    function adicionarGrupo() {
      if (UtilitarioGeral.validaLista(state.personalizacao.gruposCamposPersonalizados)) {
        const grupo = state.personalizacao.gruposCamposPersonalizados.find((g) => g.identificador === state.novoGrupo.identificador);
        if (UtilitarioGeral.valorValido(grupo)) {
          apresentarMensagemAlerta('Já foi adicionado um grupo com esse identificador.');
          focoNovoGrupo();
          return;
        }
      }

      if (state.novoGrupo.identificador === undefined || state.novoGrupo.identificador === '') {
        apresentarMensagemAlerta('O identificador do grupo deve ser preenchido.');
        return;
      }

      if (state.novoGrupo.titulo === undefined || state.novoGrupo.titulo === '') {
        apresentarMensagemAlerta('O título do grupo deve ser preenchido.');
        return;
      }

      const grupo:IGrupoCamposPersonalizado = {} as IGrupoCamposPersonalizado;
      grupo.codigo = 0;
      grupo.codigoPersonalizacaoTela = state.personalizacao.codigo;
      grupo.identificador = state.novoGrupo.identificador;
      grupo.tipoElemento = state.novoGrupo.tipoElemento;
      grupo.titulo = state.novoGrupo.titulo;
      grupo.ativo = true;
      grupo.ordem = state.personalizacao.gruposCamposPersonalizados.length;
      grupo.linhas = [] as ILinhaCamposPersonalizados[];
      grupo.campos = [] as ICampoPersonalizado[];

      state.personalizacao.gruposCamposPersonalizados.push(grupo);
      preparaObjetoInicialGrupo();
      focoNovoGrupo();
    }

    function editarGrupo(grupo: IGrupoCamposPersonalizado) {
      state.grupoEditado = grupo;
      state.exibirModalGrupos = true;
    }

    function removerGrupo(index: number, identificador: string) {
      Modal.confirm({
        title: 'Deseja remover o grupo:',
        content: `${identificador} da lista de grupos?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { state.personalizacao.gruposCamposPersonalizados.splice(index, 1); },
      });
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();
      state.personalizacao.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      if (props.operacao?.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoPersonalizacaoTela.incluir(state.personalizacao);
      } else if (props.operacao?.tipoPermissaoDados === EPermissaoDados.Alterar) {
        retorno = await servicoPersonalizacaoTela.alterar(state.personalizacao);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
        emit('confirmacao');
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function excluir() {
      Modal.confirm({
        title: 'Exclusão',
        content: 'Deseja remover a personalização desta tela?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoPersonalizacaoTela.excluir(state.personalizacao.codigo);
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemSucesso(retorno.mensagem);
            modalBase.computedVisivel = false;
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      preparaObjetoInicial();
      if (modalBase.computedVisivel) {
        state.montarModalGrupo = true;
        if (props.recurso === undefined && props.operacao.codigoRegistro > 0) {
          state.personalizacao = await servicoPersonalizacaoTela.obter(props.operacao.codigoRegistro, storeSistema.getters.codigoEmpresaOperacao());
          state.personalizacao.gruposCamposPersonalizados = await servicoPersonalizacaoTela.obterGruposCampos(props.operacao.codigoRegistro, storeSistema.getters.codigoEmpresaOperacao());
        } else if (props.recurso !== undefined) {
          if (!state.recursosDisponiveis.includes(props.recurso)) {
            apresentarMensagemAlerta('Essa tela ainda não possui esse recurso,\n por favor entre em contato  com nosso suporte.');
            if (props.recurso !== undefined) {
              modalBase.computedVisivel = false;
              return;
            }
          }
          const personalizacao = await servicoPersonalizacaoTela.obterPeloRecurso(props.recurso, storeSistema.getters.codigoEmpresaOperacao());
          if (UtilitarioGeral.valorValido(personalizacao)) {
            state.exibirBotaoExcluir = true;
            state.personalizacao = personalizacao;
            state.personalizacao.gruposCamposPersonalizados = await servicoPersonalizacaoTela.obterGruposCampos(state.personalizacao.codigo, storeSistema.getters.codigoEmpresaOperacao());
            computedOperacao.value.tipoPermissaoDados = EPermissaoDados.Alterar;
          } else {
            computedOperacao.value.tipoPermissaoDados = EPermissaoDados.Incluir;
          }
        }
      } else {
        state.montarModalGrupo = false;
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      apresentarRetornoRequisicao,
      EPermissaoDados,
      computedOperacao,
      computedTela,
      preparaObjetoInicial,
      campoNovoGrupo,
      adicionarGrupo,
      removerGrupo,
      editarGrupo,
      salvar,
      excluir,
    };
  },
});
